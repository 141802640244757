/*
 * Custom
 */
$( document ).ready(function() {

    // Order page

    var firstPlanMonth = '$10.99';
    var secondPlanMonth = '$12.99';
    var firstPlanYearly = '$59.99';
    var secondPlanYearly = '$89.99';

    var firstPlanName = 'First plan';
    var secondPlanName = 'Second plan';

    $('.pick-change').on('click', function () {
        if ($('#package-first').hasClass("active") && $('#plan-first').hasClass("active"))  {
            $('#billed-price').text(firstPlanMonth);
        }
        if ($('#package-first').hasClass("active") && $('#plan-second').hasClass("active"))  {
            $('#billed-price').text(firstPlanYearly);
        }

        if ($('#package-second').hasClass("active") && $('#plan-first').hasClass("active"))  {
            $('#billed-price').text(secondPlanMonth);
        }
        if ($('#package-second').hasClass("active") && $('#plan-second').hasClass("active"))  {
            $('#billed-price').text(secondPlanYearly);
        }
    });

    $('#package-first').on('click', function () {
        $(this).addClass('active');
        $('#package-second').removeClass('active');
        if ($(this).hasClass("active")) {
            $('#plan-first .price .number').text(firstPlanMonth);
            $('#plan-second .price .number').text(firstPlanYearly);
            $('.show-package-first').show();
            $('.show-package-second').hide();
        }
    });

    $('#package-second').on('click', function () {
        $(this).addClass('active');
        $('#package-first').removeClass('active');
        if ($(this).hasClass("active"))  {
            $('#plan-first .price .number').text(secondPlanMonth);
            $('#plan-second .price .number').text(secondPlanYearly);
            $('.show-package-first').hide();
            $('.show-package-second').show();
        }
    });

    $('#plan-first').on('click', function () {
        $(this).addClass('active');
        $('#plan-second').removeClass('active');
    });

    $('#plan-second').on('click', function () {
        $(this).addClass('active');
        $('#plan-first').removeClass('active');
    });

    $('#method-paypal').on('click', function () {
        $(this).addClass('active');
        $('#method-card').removeClass('active');
        $('.form-card').hide();
    });

    $('#method-card').on('click', function () {
        $(this).addClass('active');
        $('#method-paypal').removeClass('active');
        $('.form-card').show();
    });


    // Phone numbers range

    var $element = $('input[type="range"]');

    $element
        .rangeslider({
            polyfill: false,
            onInit: function() {
                var $handle = $('.rangeslider__handle', this.$range);
                updateHandle($handle[0], this.value);
            }
        })
        .on('input', function(e) {
            var $handle = $('.rangeslider__handle', e.target.nextSibling);
            updateHandle($handle[0], this.value);
        });

    function updateHandle(el, val) {
        el.textContent = val;
    }

    // Trial popup POST

    document.addEventListener( 'wpcf7mailsent', function( event ) {
        if ( '14420' == event.detail.contactFormId ) {
            location = 'https://limecall.com/trial-activate/';
        }
    }, false );

    // Outbound filter

    $("#outbound-filter").on("keyup", function() {
        var value = $(this).val().toLowerCase();
        $("#myTable tr").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });

    // Click faq

    $('.click-faq .accordion .card-header').on('click', function () {
        $('.click-faq .accordion .card').removeClass('active');
        $(this).parent('.click-faq .accordion .card').toggleClass('active');
    });

    // Swiper Click how it works

    var swiper = new Swiper(".swiper-click-slider", {
        loop: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });

    // Swiper Click testimonials

    var swiper = new Swiper(".swiper-click-testimonials", {
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });

    // SwiperMouseflow

    var swiper = new Swiper(".swiper-mouseflow", {
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
    });

    // Swiper numbers

    var swiper = new Swiper(".swiper-numbers-testimonials", {
        loop: true,
        spaceBetween: 30,
        slidesPerView: 3,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
        },
        breakpoints: {
            992: {
                slidesPerView: 3,
            },
            300: {
                slidesPerView: 1,
            }
        }
    });

    // Use cases testimonials

    var swiper = new Swiper(".swiper-use-cases-testimonials", {
        loop: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    // If div has background

    $(function(){
        if ($('.customer-engagement-hero').css('background-image') != 'none') {
            $('.customer-engagement-hero').addClass('with-bg');
        }
    });

    // Blog category active url

    $(function(){
        var current = location.pathname;
        $('.blog-categories a').each(function(){
            var $this = $(this);
            // if the current path is like this link, make it active
            if($this.attr('href').indexOf(current) !== -1){
                $this.addClass('active');
            } else {
                $this.removeClass('active');
            }
        })
    });

    // Contact options

    $('#contact_options').change(function(){
        $('.contacts__item').hide();
        $('#' + $(this).val()).show();
    });

    // Navigation

    $(".navigation .item").mouseenter(function() {
        $(this).addClass('open');
    }).mouseleave(function() {
        $(this).removeClass('open');
    });

    // Sticky info

    $(window).on("scroll", function () {
        if ($(this).scrollTop() > 200) {
            $('.sticky-info').addClass('show');
        } else {
            $('.sticky-info').removeClass('show');
        }
    });

    // Sticky header

    $(window).on("scroll", function () {
        if ($(this).scrollTop() > 1) {
            $('.header-sticky').addClass('show');
            $('.header').addClass('remove');
        } else {
            $('.header-sticky').removeClass('show');
            $('.header').removeClass('remove');
        }
    });

    // Sticky blog categories

    $(window).on("scroll", function () {
        if ($(this).scrollTop() > 150) {
            $('.header-blog--categories').addClass('active');
        } else {
            $('.header-blog--categories').removeClass('active');
        }
    });

    // Mobile nav

    $('.header_wrap__icon').on('click', function () {
        $('.header_mobile').slideDown();
        $('html').addClass('over');
        $('body').addClass('over');
    });

    $('.header_mobile__top .close').on('click', function () {
        $('.header_mobile').slideUp();
        $('html').removeClass('over');
        $('body').removeClass('over');
    });

    // Hover nav

    if($(window).innerWidth() <= 767) {
        $('.header_mobile .header_nav .menu-item-has-children').on('click', function () {
            $(this).toggleClass('active');
        });
    } else {
        $('.menu-item-has-children').mouseenter(function() {
            $(this).addClass('active');
            $(this).children('.sub-menu').stop().show()
        }).mouseleave(function() {
            $(this).removeClass('active');
            $(this).children('.sub-menu').stop().hide()
        });
    }

    // Security anchor

    $(".security-features_link").on("click", function(e) {
        e.preventDefault();
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top - 50
        }, 1000);
    });

    // Free tools active url

    $(function() {
        $('.free-tools-all a[href^="/' + location.pathname.split("/")[1] + '"]').addClass('active');
    });

    // Slider demo testimonials

    var swiper = new Swiper('.swiper-demo', {
        slidesPerView: 1,
        speed: 1000,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // Customer success help

    var swiper = new Swiper('.swiper-help', {
        speed: 1000,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // Customer success testimonials

    var swiper = new Swiper('.swiper-testimonials', {
        speed: 1000,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // Tooltip

    $('[data-toggle="tooltip"]').tooltip();

    // Pricing tooltop

    $(".hover-tooltip").mouseenter(function() {
        $(this).find('.tooltip').show();
    }).mouseleave(function() {
        $(this).find('.tooltip').hide();
    });

    // All features fixed bar

    var content = $('.af_list');
    if (content.length) {
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            var offset = content.offset();
            var height = content.height();
            if (offset.top <= scroll && scroll <= offset.top + height) {
                $('.af_bar').addClass("fixed");
            } else {
                $('.af_bar').removeClass('fixed');
            }
        });
    }

    // Cache selectors

    var lastId,
        topMenu = $("#mainNav"),
        topMenuHeight = topMenu.outerHeight()+15,
        menuItems = topMenu.find("a"),
        scrollItems = menuItems.map(function(){
            var item = $($(this).attr("href"));
            if (item.length) { return item; }
        });

    menuItems.click(function(e){
        var href = $(this).attr("href"),
            offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
        $('html, body').stop().animate({
            scrollTop: offsetTop + 40
        }, 900);
        e.preventDefault();
    });

    $(window).scroll(function(){
        var fromTop = $(this).scrollTop()+topMenuHeight;

        var cur = scrollItems.map(function(){
            if ($(this).offset().top < fromTop)
                return this;
        });
        cur = cur[cur.length-1];
        var id = cur && cur.length ? cur[0].id : "";

        if (lastId !== id) {
            lastId = id;
            menuItems
                .parent().removeClass("active")
                .end().filter("[href='#"+id+"']").parent().addClass("active");
        }
    });

    // Guides add class scroll

    $(document).on("scroll", onScroll);

    $('.guides__bar a[href^="#"]').on('click', function (e) {
        e.preventDefault();
        $(document).off("scroll");

        $('a').each(function () {
            $(this).removeClass('active');
        });
        $(this).addClass('active');

        var target = this.hash,
            menu = target;
        $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 500, 'swing', function () {
            window.location.hash = target;
            $(document).on("scroll", onScroll);
        });
    });

    function onScroll(event){
        var scrollPos = $(document).scrollTop();
        $('.guides__bar a').each(function () {
            var currLink = $(this);
            var refElement = $(currLink.attr("href"));
            if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                $('.guides__bar a').removeClass("active");
                currLink.addClass("active");
            }
            else{
                currLink.removeClass("active");
            }
        });
    }

    // Progress

    $(document).on('ready', function() {
        var winHeight = $(window).height(),
            docHeight = $(document).height(),
            progressBar = $('progress'),
            max, value;

        /* Set the max scrollable area */
        max = docHeight - winHeight;
        progressBar.attr('max', max);

        $(document).on('scroll', function(){
            value = $(window).scrollTop();
            progressBar.attr('value', value);
        });
    });

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 500) {
            $(".sticky_header").show();
        } else {
            $(".sticky_header").hide();
        }
    });

    // Pricing

    $('.pricing_item__btn .btn_green').on('click', function () {
        $('.popup-button .material-icons').click();
    });

    // Slider tabs

    // var sliders = [];
    //
    // $('.swiper-container').each(function(index, element){
    //
    //     $(this).addClass('s'+index);
    //     var slider = new Swiper('.s'+index, {
    //         observer: true,
    //         observeParents: true,
    //         navigation: {
    //             nextEl: '.swiper-button-next',
    //             prevEl: '.swiper-button-prev',
    //         },
    //     });
    //     sliders.push(slider);
    //
    // });

    // Scroll to element

    $(".pricing_all").on('click', function(e) {
        e.preventDefault();
        var target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: ($(target).offset().top)
        }, 800);
    });

    $(".how_header__text a").on('click', function(e) {
        e.preventDefault();
        var target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: ($(target).offset().top)
        }, 800);
    });

    $(".glossary_list__letters .box a").on('click', function(e) {
        e.preventDefault();
        var target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: ($(target).offset().top)
        }, 1000);
    });

    $(".use-cases-tabs .wrapper .item a").on('click', function(e) {
        e.preventDefault();
        var target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: ($(target).offset().top)
        }, 800);
    });

    // Integration show/hide

    $('.integration_item').on( "click", function(e) {
        e.preventDefault();
        $('.integration_item').removeClass('active');
        $(this).addClass('active');
        var id = $(this).attr('value');
        $(".integration_info").each(function(){
            $(this).hide();
            if($(this).attr('data-filter') == id) {
                $(this).show();
            }
        });
    });

    // Integrations slider

    var swiper = new Swiper('.slider-integration', {
        effect: 'fade',
        allowTouchMove: false,
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
        },
    });

    // Dynamic hero

    var $anchors = $('.dynamic-hero .bg'), counter = 1;
    setInterval(function(){
        $anchors.removeClass('display');
        $anchors.eq(counter++ % $anchors.length).addClass('display');
    }, 8000);

    var $anchorsImg = $('.dynamic-hero .image img'), counterImg = 1;
    setInterval(function(){
        $anchorsImg.removeClass('display');
        $anchorsImg.eq(counterImg++ % $anchorsImg.length).addClass('display');
    }, 8000);

    var $anchorsWord = $('.dynamic-hero .words'), counterWord = 1;
    setInterval(function(){
        $anchorsWord.removeClass('display');
        $anchorsWord.eq(counterWord++ % $anchorsWord.length).addClass('display');
    }, 8000);

    // Sales tools

    var getFilter = function (category) {
        var filter = $("#filters ." + category + ":checked").map(function () {
            return '[data-filter*="' + this.value + '"]';
        }).get().join(",");
        filter = (filter.length > 0) ? filter : "*";
        return filter;
    };

    $("#filters select").change(function () {
        var all = $(".filterme");
        var tgts = all.filter(getFilter("category")).filter(getFilter("cost")).filter(getFilter("features")).filter(getFilter("rating"));
        all.not(tgts).hide();
        tgts.show();
    });

    // Refunds accordion

    $('.accordion .btn').on('click', function () {
        $(this).toggleClass('opened');
    });

    // Real estate

    $('.accordion h3').on('click', function () {
        $(this).toggleClass('opened');
    });

    // Testimonials slider

    if ($(".swiper-container.gallery-top")[0]){
        var galleryTop = new Swiper('.gallery-top', {
            speed: 800,
            spaceBetween: 10,
            effect: 'fade',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

        });
        var galleryThumbs = new Swiper('.gallery-thumbs', {
            speed: 800,
            spaceBetween: 10,
            centeredSlides: true,
            slidesPerView: 'auto',
            touchRatio: 0.2,
            slideToClickedSlide: true,

        });
        galleryTop.controller.control = galleryThumbs;
        galleryThumbs.controller.control = galleryTop;
    }

    // Customer services highlight

    $(document).on("scroll", onScroll);

    $('#nav-chapter a[href^="#"]').on('click', function (e) {
        e.preventDefault();
        $(document).off("scroll");

        $('a').each(function () {
            $(this).removeClass('active');
        });
        $(this).addClass('active');

        var target = this.hash,
            menu = target;
        $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 500, 'swing', function () {
            window.location.hash = target;
            $(document).on("scroll", onScroll);
        });
    });

    function onScroll(event){
        var scrollPos = $(document).scrollTop();
        $('#nav-chapter a').each(function () {
            var currLink = $(this);
            var refElement = $(currLink.attr("href"));
            if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                $('#nav-chapter a').removeClass("active");
                currLink.addClass("active");
            }
            else{
                currLink.removeClass("active");
            }
        });
    }

    // Customer services fixed bar

    var content1 = $('.chapters');
    if (content1.length) {
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            var offset = content1.offset();
            var height = content1.height();
            if (offset.top <= scroll && scroll <= offset.top + height) {
                $('.nav-chapter').addClass("fixed");
            } else {
                $('.nav-chapter').removeClass('fixed');
            }
        });
    }

    // Customer success fixed bar

    var content2 = $('.cs-meet');
    if (content2.length) {
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            var offset = content2.offset();
            var height = content2.height();
            if (offset.top <= scroll && scroll <= offset.top + height) {
                $('.cs-meet--nav').addClass("fixed");
            } else {
                $('.cs-meet--nav').removeClass('fixed');
            }
        });
    }

    $(".cs-meet--nav a").on('click', function(e) {
        e.preventDefault();
        var target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: ($(target).offset().top)
        }, 800);
    });

    // Implementation scroll to anchor

    $(".implementation-list .list-bar a").on('click', function(e) {
        e.preventDefault();
        var target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: ($(target).offset().top - 30)
        }, 800);
    });

    // Competitors reviews slider

    var swiper = new Swiper('.swiper-competitors-reviews', {
        spaceBetween: 30,
        effect: 'fade',
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
    });

    // Typed js

    // var typed = new Typed('#typed', {
    //     stringsElement: '#typed-strings',
    //     loop: true,
    //     typeSpeed: 50,
    //     backSpeed: 50,
    //     backDelay: 700,
    // });
});